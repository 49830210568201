import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'cmp-plan-card',
    templateUrl: './plan-card.component.html',
    styleUrls: ['./plan-card.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class PlanCardComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() checkedPlan: any;
  @Output() selected: EventEmitter<any> = new EventEmitter();

  checked = false;
  plan = '';

  ngOnInit() {
    this.checked = this.data._id === this.checkedPlan._id;
  }

  ngOnChanges() {
    this.checked = this.data._id === this.checkedPlan._id;
  }

  handleClick() {
    this.selected.emit(this.data);
  }
}
