<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <!-- <div class="cal-cell-top" [ngClass]="{'no-events': day.events.length === 0}"> -->
  <div class="cal-cell-top">
    <span class="cal-day-badge" style="display: none"></span>
    <span
      class="cal-day-number"
      [ngClass]="{
        'cal-number-medium': day?.meta?.season === 'MID_SEASON',
        'cal-number-high': day?.meta?.season === 'HIGH_SEASON',
        'cal-number-low': day?.meta?.season === 'LOW_SEASON'
      }"
      >{{ day.date | calendarDate : 'monthViewDayNumber' : locale }}</span
    >
  </div>
</ng-template>

<!-- TODO GET LOCALE FROM STORE -->
<div class="season-labels-container">
  <div class="season-wrapper">
    <div class="status-calendar season-label-low">
      <div *ngIf="(isMobile$ | async) === false"> {{ labels?.seasonLow }} </div>
      <div *ngIf="(isMobile$ | async) === true"> € </div>
    </div>
  </div>
  <div class="season-wrapper">
    <div class="status-calendar season-label-medium">
      <div *ngIf="(isMobile$ | async) === false"> {{ labels?.seasonMedium }} </div>
      <div *ngIf="(isMobile$ | async) === true"> €€ </div>
    </div>
  </div>
  <div class="season-wrapper">
    <div class="status-calendar season-label-high">
      <div *ngIf="(isMobile$ | async) === false"> {{ labels?.seasonHigh }} </div>
      <div *ngIf="(isMobile$ | async) === true"> €€€ </div>
    </div>
  </div>
  <div class="season-wrapper">
    <div class="status-calendar season-label-complete">
      <div> {{ labels?.complete }} </div>
    </div>
  </div>
</div>
<div class="calendars">
  <div class="controls" *ngIf="(isMobile$ | async) === false">
    <img class="previous-month" src="assets/v3/arrow.svg" alt="Previous month" (click)="previousMonth()" />
  </div>

  <div class="month-view" (swipeleft)="onSwipeLeft($event,-1)" (swiperight)="onSwipeRight($event,1)">
    <div *ngIf="isMobile$ | async" class="mobile-controls">
      <div class="controls">
        <img class="previous-month" src="assets/v3/arrow.svg" alt="Previous month" (click)="previousMonth()" />
      </div>
      <h5>{{ viewDate | calendarDate : 'month' + 'ViewTitle' : locale }}</h5>
      <div class="controls">
        <img class="next-month" src="assets/v3/arrow.svg" alt="Next month" (click)="nextMonth()" />
      </div>
    </div>

    <h5 *ngIf="(isMobile$ | async) === false">{{ viewDate | calendarDate : 'month' + 'ViewTitle' : locale }}</h5>
    <mwl-calendar-month-view
      [locale]="locale"
      [viewDate]="viewDate"
      [events]="events"
      [weekStartsOn]="weekStartsOn"
      [refresh]="refresh"
      (dayClicked)="dayClicked($event.day)"
      (eventTimesChanged)="eventTimesChanged($event)"
      (beforeViewRender)="beforeMonthViewRender($event)"
      [excludeDays]="[1, 2, 3, 4]"
      [cellTemplate]="customCellTemplate"
    >
    </mwl-calendar-month-view>
  </div>

  <div *ngIf="(isMobile$ | async) === false" class="month-view">
    <h5>{{ nextMonthViewDate | calendarDate : 'month' + 'ViewTitle' : locale }}</h5>
    <mwl-calendar-month-view
      [locale]="locale"
      [viewDate]="nextMonthViewDate"
      [events]="events"
      [weekStartsOn]="weekStartsOn"
      [refresh]="refresh"
      [excludeDays]="[1, 2, 3, 4]"
      (dayClicked)="dayClicked($event.day)"
      (eventTimesChanged)="eventTimesChanged($event)"
      (beforeViewRender)="beforeMonthViewRender($event)"
      [cellTemplate]="customCellTemplate"
    >
    </mwl-calendar-month-view>
  </div>

  <div *ngIf="(isMobile$ | async) === false" class="month-view">
    <h5>{{ next2MonthViewDate | calendarDate : 'month' + 'ViewTitle' : locale }}</h5>
    <mwl-calendar-month-view
      [locale]="locale"
      [viewDate]="next2MonthViewDate"
      [events]="events"
      [weekStartsOn]="weekStartsOn"
      [refresh]="refresh"
      [excludeDays]="[1, 2, 3, 4]"
      (dayClicked)="dayClicked($event.day)"
      (eventTimesChanged)="eventTimesChanged($event)"
      (beforeViewRender)="beforeMonthViewRender($event)"
      [cellTemplate]="customCellTemplate"
    >
    </mwl-calendar-month-view>
  </div>

  <div *ngIf="(isMobile$ | async) === false" class="month-view">
    <h5>{{ next3MonthViewDate | calendarDate : 'month' + 'ViewTitle' : locale }}</h5>
    <mwl-calendar-month-view
      [locale]="locale"
      [viewDate]="next3MonthViewDate"
      [events]="events"
      [weekStartsOn]="weekStartsOn"
      [refresh]="refresh"
      [excludeDays]="[1, 2, 3, 4]"
      (dayClicked)="dayClicked($event.day)"
      (eventTimesChanged)="eventTimesChanged($event)"
      (beforeViewRender)="beforeMonthViewRender($event)"
      [cellTemplate]="customCellTemplate"
    >
    </mwl-calendar-month-view>
  </div>

  <div *ngIf="(isMobile$ | async) === false" class="month-view">
    <h5>{{ next4MonthViewDate | calendarDate : 'month' + 'ViewTitle' : locale }}</h5>
    <mwl-calendar-month-view
      [locale]="locale"
      [viewDate]="next4MonthViewDate"
      [events]="events"
      [weekStartsOn]="weekStartsOn"
      [refresh]="refresh"
      [excludeDays]="[1, 2, 3, 4]"
      (dayClicked)="dayClicked($event.day)"
      (eventTimesChanged)="eventTimesChanged($event)"
      (beforeViewRender)="beforeMonthViewRender($event)"
      [cellTemplate]="customCellTemplate"
    >
    </mwl-calendar-month-view>
  </div>
  <div class="controls" *ngIf="(isMobile$ | async) === false">
    <img class="next-month" src="assets/v3/arrow.svg" alt="Next month" (click)="nextMonth()" />
  </div>
</div>
