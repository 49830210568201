import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'cmp-plan-wrapper',
    templateUrl: './plan-wrapper.component.html',
    styleUrls: ['./plan-wrapper.component.scss'],
    standalone: true,
})
export class PlanWrapperComponent implements OnChanges {
  @Input() data: any;
  @Output() planSelected: EventEmitter<any> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    let checkedPlan = changes.data.currentValue;
    this.planSelected.emit(checkedPlan);
  }
}
