<div class="plan-wrapper card" [ngClass]="{ checked: checked }" (click)="handleClick()">
  <img [src]="data?.image" alt="Plan" class="img-fluid" />
  <div class="info non-selectable col-md-10 col-8">
    <img class="icon" [src]="checked ? data?.iconChecked : data?.iconUnchecked" />
    <div class="text">{{ data?.text }}</div>
    <div class="price">
      <div class="from">{{ data?.from }}</div>
      <div class="price-number">{{ data?.price }}</div>
    </div>
  </div>
</div>
